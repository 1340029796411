// src/pages/BeautyRoutinesPage.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../../styles/BeautyRoutinePage.css';
import { RoutineList } from '../../General/RoutineList.js';

const BeautyRoutinesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedRoutines, setSelectedRoutines] = useState([]);

  const [expandedStepIds, setExpandedStepIds] = useState([]);
  const [expandedProductRecIds, setExpandedProductRecIds] = useState([]);
  const [shortenedStates, setShortenedStates] = useState({});

  // Retrieve routines from location state
  useEffect(() => {
    if (location.state && location.state.routines) {
      const { routines } = location.state;
      // Validate routines array
      if (Array.isArray(routines) && routines.length === 6) {
        // Create a map for faster lookup
        const routineMap = new Map(RoutineList.map(r => [r.id, r]));
        // Map over the routines array to preserve order
        const selected = routines.map(id => routineMap.get(id)).filter(Boolean);
        if (selected.length === routines.length) {
          setSelectedRoutines(selected);
        } else {
          console.error('Some routines were not found in RoutineList:', routines);
          navigate('/results', { replace: true });
        }
      } else {
        console.error('Invalid routines data:', routines);
        // Optionally, navigate back or show an error
        navigate('/results', { replace: true });
      }
    } else {
      console.error('No routines data passed.');
      // Optionally, navigate back or show an error
      navigate('/results', { replace: true });
    }
  }, [location.state, navigate]);

  // Function to toggle step expansion
  const toggleStepExpansion = (stepId) => {
    setExpandedStepIds((prevExpandedStepIds) =>
      prevExpandedStepIds.includes(stepId)
        ? prevExpandedStepIds.filter((id) => id !== stepId)
        : [...prevExpandedStepIds, stepId]
    );
  };

  // Function to toggle product recommendations dropdown
  const toggleProductRec = (routineId) => {
    setExpandedProductRecIds((prevExpandedProductRecIds) =>
      prevExpandedProductRecIds.includes(routineId)
        ? prevExpandedProductRecIds.filter((id) => id !== routineId)
        : [...prevExpandedProductRecIds, routineId]
    );
  };

  // Function to toggle shortened routine for a specific routine
  const toggleShortened = (routineId) => {
    setShortenedStates((prevShortenedStates) => ({
      ...prevShortenedStates,
      [routineId]: !prevShortenedStates[routineId],
    }));
  };

  // Helper function to check if there are any products, including 'general'
  const hasProducts = (products) => {
    if (!products) return false;
    return ['beginner', 'advanced', 'luxury', 'general'].some(
      (category) => products[category] && products[category].length > 0
    );
  };

  // Define the product categories in the desired order
  const productCategories = ['beginner', 'advanced', 'luxury', 'general'];

  return (
    <div className="beauty-routine-page">
      <h1>Your Comprehensive Beauty Routine</h1>
      <div className="steps-grid">
        {selectedRoutines.map((routine, index) => (
          <div
            key={routine.id}
            className={`step-card ${expandedStepIds.includes(routine.id) ? 'expanded' : ''}`}
            onClick={() => toggleStepExpansion(routine.id)}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                toggleStepExpansion(routine.id);
              }
            }}
          >
            <div className="step-header">
              {/* Priority Badge */}
              <div className="priority-badge">
                {index + 1}
              </div>
              <h2>{routine.name}</h2>
              <span className="expand-icon">
                {expandedStepIds.includes(routine.id) ? '−' : '+'}
              </span>
            </div>
            <p>{routine.description}</p>
            
            {/* Conditionally Render the Toggle Switch */}
            {routine.canBeShortened && expandedStepIds.includes(routine.id) && (
              <div
                className="toggle-container"
                onClick={(e) => e.stopPropagation()} // Prevents step collapse when toggling
              >
                <label className="switch" aria-label={`Toggle between full and shortened routine for ${routine.name}`}>
                  <input 
                    type="checkbox" 
                    checked={shortenedStates[routine.id] || false} 
                    onChange={() => toggleShortened(routine.id)} 
                    aria-checked={shortenedStates[routine.id] || false}
                  />
                  <span className="slider"></span>
                </label>
                <span className="toggle-label">
                  {shortenedStates[routine.id] ? 'Shortened Routine' : 'Full Routine'}
                </span>
              </div>
            )}
            
            {expandedStepIds.includes(routine.id) && (
              <div className="step-content">
                {routine.routines
                  .filter((subRoutine) => !routine.canBeShortened || !shortenedStates[routine.id] || subRoutine.includeInShortened)
                  .map((subRoutine, subIndex) => {
                    // Create a unique ID for each sub-routine
                    const routineId = `${routine.id}-${subIndex}`;
                    return (
                      <div key={routineId} className="routine-section">
                        <h3>{subRoutine.name}</h3>
                        <p>{subRoutine.description}</p>
                        <ol className="routine-steps">
                          {subRoutine.steps.map((instruction, idx) => (
                            <li key={idx}>{instruction}</li>
                          ))}
                        </ol>
                        <p className="routine-feedback">
                          <strong>Feedback:</strong> {subRoutine.feedback}
                        </p>
                        
                        {/* Conditionally render Product Recommendations */}
                        {hasProducts(subRoutine.products) && (
                          <div className="product-recommendations">
                            {/* Dropdown Toggle Button */}
                            <button
                              className="product-recommendations-toggle"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent triggering step expansion
                                toggleProductRec(routineId);
                              }}
                              aria-expanded={expandedProductRecIds.includes(routineId)}
                              aria-controls={`product-recommendations-${routineId}`}
                            >
                              {expandedProductRecIds.includes(routineId)
                                ? 'Hide Product Recommendations ▲'
                                : 'Show Product Recommendations ▼'}
                            </button>

                            {/* Dropdown Content */}
                            {expandedProductRecIds.includes(routineId) && (
                              <div
                                id={`product-recommendations-${routineId}`}
                                className="products-container"
                              >
                                {productCategories.map((category) => {
                                  if (subRoutine.products[category] && subRoutine.products[category].length > 0) {
                                    const product = subRoutine.products[category][0]; // Assuming the first product
                                    return (
                                      <div key={category} className="product-category-box">
                                        <div className={`product-category-header ${category}-box`}>
                                          {category.charAt(0).toUpperCase() + category.slice(1)}
                                        </div>
                                        {/* Make the entire product-card an <a> tag */}
                                        <a
                                          href={product.link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="product-card"
                                          aria-label={`View details for ${product.name}`}
                                          onClick={(e) => e.stopPropagation()} // Prevent event bubbling
                                        >
                                          <img
                                            src={product.image}
                                            alt={product.name}
                                            className="product-image"
                                            loading="lazy" // Enhances performance
                                          />
                                          <h5>{product.name}</h5>
                                          <p>{product.description}</p>
                                          <span className="buy-button">Buy Now</span>
                                        </a>
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="back-link">
        <Link to="/results" className="back-button">
          &larr; Back to Results
        </Link>
      </div>
    </div>
  );
};

export default BeautyRoutinesPage;
