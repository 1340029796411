// src/pages/UnauthorizedResultsPage.js
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import UnauthorizedFrontResultsComponent from '../components/UnauthorizedFrontResultsComponent';
import FailedFrontResultsComponent from '../../General/FailedFrontResultsComponent';
import ProgressBar from '../../General/ProgressBar';
import BellCurve from '../../General/BellCurve';
import CircularProgress from '@mui/material/CircularProgress';
import colors from '../../styles/colors';
import { calculateScorePercentile } from '../../Authorized/components/utils';
import '../../styles/ResultsPage.css';

function UnauthorizedResultsPage() {
  const { state } = useLocation();
  const navigate = useNavigate(); // Initialize navigate
  const { data, frontImage } = state || {}; // Removed sideImage
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data && frontImage) {
      setLoading(false);
    } else {
      console.error('Essential data missing. Redirecting to upload.');
      // Redirect to /upload if essential data is missing
      navigate('/upload', { replace: true });
    }
  }, [data, frontImage, navigate]); // Removed sideImage from dependencies

  const calculateOverallScore = useCallback(() => {
    if (!data?.frontResult?.score) {
      return 0;
    }

    const frontScore = data.frontResult.score;

    return frontScore.toFixed(2);
  }, [data?.frontResult?.score]);

  const overallScore = useMemo(() => calculateOverallScore(), [calculateOverallScore]);
  const overallScoreValue = useMemo(() => Math.ceil(overallScore * 10), [overallScore]);
  const overallScorePercentile = useMemo(() => calculateScorePercentile(overallScoreValue / 10), [overallScoreValue]);

  return (
    <div style={{ background: colors.background, padding: 40, minWidth: '1100px', overflowX: 'auto' }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress sx={{ color: colors.primary }} size={150} />
        </div>
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ 
              marginRight: '20px',
              display: 'flex',
              justifyContent: 'flex-start',
              flex: '1 1 25%',
              height: '150px', 
              width: 'auto', 
              objectFit: 'contain',
            }}>
              <BellCurve score={overallScorePercentile}/>
            </div>
            <div style={{ textAlign: 'center', flex: '1 1 25%' }}>
              <h2>Overall Score: {overallScoreValue}</h2>
              <ProgressBar percent={overallScorePercentile} style={{ width: '100%' }} />
            </div>
            <div style={{ 
              display: 'flex',
              justifyContent: 'flex-end',
              flex: '1 1 25%',
              height: '150px', 
              width: 'auto', 
              objectFit: 'contain',
            }}>
              {/* Removed Side Image Toggle */}
              {data?.frontResult ? (
                <img
                  src={`data:image/jpeg;base64,${frontImage}`}
                  alt="Front Profile"
                  style={{ 
                    cursor: 'default', // No longer clickable
                    height: '150px', 
                    width: 'auto', 
                    border: `8px solid ${colors.primary}`, 
                    borderRadius: '12px',
                    overflow: 'hidden'
                  }}
                />
              ) : (
                <div>No front profile</div>
              )}
            </div>
          </div>
          <div>
            {data?.frontResult && (
              data.frontResult.score === 0 ? (
                <FailedFrontResultsComponent imagePath={`data:image/jpeg;base64,${frontImage}`} />
              ) : (
                <UnauthorizedFrontResultsComponent 
                  data={data.frontResult} 
                  routines={data.routines}
                  fullData = {data}
                  imagePath={`data:image/jpeg;base64,${frontImage}`} 
                  frontImage={frontImage}
                />
              )
            )}
          </div>
          {/* <div style={{ marginTop: '20px' }}>
            <h2>Data</h2>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </div> */}
        </>
      )}
    </div>
  );
}

export default UnauthorizedResultsPage;
