import React, { useEffect, useRef, useState, useMemo } from 'react';
import ProcessedImage from '../../../../General/ProcessedImage';
import FrontTraitsToggle from '../../FrontTraitsToggle';
import FrontRatiosToggle from '../../FrontRatiosToggle';
import ProgressBar from '../../../../General/ProgressBar';
import colors from '../../../../styles/colors';
import BellCurve from '../../../../General/BellCurve';
import { calculateScorePercentile } from '../../utils';
import { useNavigate } from 'react-router-dom';
import '../../../../styles/GlowUpContainerComponent.css';

function FrontScanResultsComponent({ data, routines, imagePath }) {
  const canvasRef = useRef(null);
  const [visibleFeatures, setVisibleFeatures] = useState({
    pupilDistance: false,
    leftEyeLength: false,
    rightEyeLength: false,
    faceLength: false,
    faceWidth: false,
    noseWidth: false,
    noseHeight: false,
    mouthWidth: false,
    eyeSpacing: false,
    eyeOuterDistance: false,
    leftEyeHeight: false,
    rightEyeHeight: false,
    jawWidth: false,
    chinHeight: false,
    philtrumLength: false,
    midfaceLength: false,
    upperLipHeight: false,
    lowerLipHeight: false,
    eyeSeparationRatio: false,
    eyeAspectRatioLeft: false,
    eyeAspectRatioRight: false,
    jawWidthRatio: false,
    chinPhiltrumRatio: false,
    midfaceRatio: false,
    midfaceCompactness: false,
    mouthNoseHarmony: false,
    eyeSpacingRatio: false,
    noseHeightToWidthRatio: false,
    lipRatio: false,
    facialThirds: false,
  });

  const [showAllFeatures, setShowAllFeatures] = useState(false);
  const [sortOrder, setSortOrder] = useState('default');
  const [imageHeight, setImageHeight] = useState(0);
  const [toggleView, setToggleView] = useState('traits');

  const navigate = useNavigate();

  const toggleFeature = (feature) => {
    setVisibleFeatures((prev) => ({
      ...prev,
      [feature]: !prev[feature],
    }));
  };

  useEffect(() => {
    if (data && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.src = imagePath;

      image.onload = () => {
        const screenHalfWidth = window.innerWidth / 2;
        const scale = screenHalfWidth / image.width;

        const calculatedHeight = image.height * scale;
        setImageHeight(calculatedHeight);

        canvas.width = screenHalfWidth;
        canvas.height = calculatedHeight;

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        ctx.save();
        ctx.scale(scale, scale);

        const drawLine = (point1, point2) => {
          ctx.beginPath();
          ctx.moveTo(point1[0], point1[1]);
          ctx.lineTo(point2[0], point2[1]);
          ctx.stroke();
        };

        ctx.strokeStyle = 'green';
        ctx.lineWidth = 3 / scale;

        const drawRatio = (ratio, points) => {
          points.forEach(pair => {
            drawLine(data.measurements_coords[pair[0]], data.measurements_coords[pair[1]]);
          });
        };

        const drawHorizontalLines = (points) => {
          const colors = ['red', 'green', 'blue'];
          const labels = ['Forehead Line', 'Brow Line', 'Nose Line', 'Chin Line'];

          points.forEach((point, index) => {
            ctx.strokeStyle = colors[index % colors.length];
            ctx.lineWidth = 2 / scale;
            drawLine([0, point[1]], [canvas.width / scale, point[1]]);
            ctx.fillStyle = colors[index % colors.length];
            ctx.fillText(labels[index], 10, point[1] - 10);
          });
        };

        if (visibleFeatures.pupilDistance) {
          drawLine(data.measurements_coords["Left Pupil"], data.measurements_coords["Right Pupil"]);
        }
        if (visibleFeatures.leftEyeLength) {
          drawLine(data.measurements_coords["Left Eye Start"], data.measurements_coords["Left Eye End"]);
        }
        if (visibleFeatures.rightEyeLength) {
          drawLine(data.measurements_coords["Right Eye Start"], data.measurements_coords["Right Eye End"]);
        }
        if (visibleFeatures.faceLength) {
          drawLine(data.measurements_coords["Chin Point"], data.measurements_coords["Forehead Point"]);
        }
        if (visibleFeatures.faceWidth) {
          drawLine(data.measurements_coords["Left Face Point"], data.measurements_coords["Right Face Point"]);
        }
        if (visibleFeatures.noseWidth) {
          drawLine(data.measurements_coords["Nose Left Point"], data.measurements_coords["Nose Right Point"]);
        }
        if (visibleFeatures.noseHeight) {
          drawLine(data.measurements_coords["Nose Top Point"], data.measurements_coords["Nose Bottom Point"]);
        }
        if (visibleFeatures.mouthWidth) {
          drawLine(data.measurements_coords["Mouth Left Point"], data.measurements_coords["Mouth Right Point"]);
        }
        if (visibleFeatures.eyeSpacing) {
          drawLine(data.measurements_coords["Left Eye Inner Point"], data.measurements_coords["Right Eye Inner Point"]);
        }
        if (visibleFeatures.eyeOuterDistance) {
          drawLine(data.measurements_coords["Left Eye Outer Point"], data.measurements_coords["Right Eye Outer Point"]);
        }
        if (visibleFeatures.leftEyeHeight) {
          drawLine(data.measurements_coords["Left Eye Height Start"], data.measurements_coords["Left Eye Height End"]);
        }
        if (visibleFeatures.rightEyeHeight) {
          drawLine(data.measurements_coords["Right Eye Height Start"], data.measurements_coords["Right Eye Height End"]);
        }
        if (visibleFeatures.jawWidth) {
          drawLine(data.measurements_coords["Jaw Left Point"], data.measurements_coords["Jaw Right Point"]);
        }
        if (visibleFeatures.chinHeight) {
          drawLine(data.measurements_coords["Chin Height Start"], data.measurements_coords["Chin Height End"]);
        }
        if (visibleFeatures.philtrumLength) {
          drawLine(data.measurements_coords["Philtrum Start"], data.measurements_coords["Philtrum End"]);
        }
        if (visibleFeatures.midfaceLength) {
          drawLine(data.measurements_coords["Midface Start"], data.measurements_coords["Midface End"]);
        }
        if (visibleFeatures.upperLipHeight) {
          drawLine(data.measurements_coords["Upper Lip Top Point"], data.measurements_coords["Upper Lip Bottom Point"]);
        }
        if (visibleFeatures.lowerLipHeight) {
          drawLine(data.measurements_coords["Lower Lip Top Point"], data.measurements_coords["Lower Lip Bottom Point"]);
        }
        if (visibleFeatures.bitemporalWidth) {
          drawLine(data.measurements_coords["Left Temple Point"], data.measurements_coords["Right Temple Point"]);
        }
        if (visibleFeatures.facialThirds) {
          const points = [
            data.measurements_coords["Forehead Point"],
            data.measurements_coords["Brow Line Point"],
            data.measurements_coords["Nose Base Point"],
            data.measurements_coords["Chin Point"]
          ];
          drawHorizontalLines(points);
        }

        // Ratios
        if (visibleFeatures.eyeSeparationRatio) {
          drawRatio('eyeSeparationRatio', [["Left Pupil", "Right Pupil"], ["Left Face Point", "Right Face Point"]]);
        }
        if (visibleFeatures.eyeAspectRatioLeft) {
          drawRatio('eyeAspectRatioLeft', [["Left Eye Start", "Left Eye End"], ["Left Eye Height Start", "Left Eye Height End"]]);
        }
        if (visibleFeatures.eyeAspectRatioRight) {
          drawRatio('eyeAspectRatioRight', [["Right Eye Start", "Right Eye End"], ["Right Eye Height Start", "Right Eye Height End"]]);
        }
        if (visibleFeatures.jawWidthRatio) {
          drawRatio('jawWidthRatio', [["Jaw Left Point", "Jaw Right Point"], ["Left Face Point", "Right Face Point"]]);
        }
        if (visibleFeatures.chinPhiltrumRatio) {
          drawRatio('chinPhiltrumRatio', [["Chin Height Start", "Chin Height End"], ["Philtrum Start", "Philtrum End"]]);
        }
        if (visibleFeatures.midfaceRatio) {
          drawRatio('midfaceRatio', [["Left Pupil", "Right Pupil"], ["Midface Start", "Midface End"]]);
        }
        if (visibleFeatures.midfaceCompactness) {
          drawRatio('midfaceCompactness', [["Left Face Point", "Right Face Point"], ["Midface Start", "Midface End"]]);
        }
        if (visibleFeatures.mouthNoseHarmony) {
          drawRatio('mouthNoseHarmony', [["Mouth Left Point", "Mouth Right Point"], ["Nose Left Point", "Nose Right Point"]]);
        }
        if (visibleFeatures.eyeSpacingRatio) {
          drawRatio('eyeSpacingRatio', [["Left Eye Inner Point", "Right Eye Inner Point"], ["Left Eye Outer Point", "Right Eye Outer Point"]]);
        }
        if (visibleFeatures.noseHeightToWidthRatio) {
          drawRatio('noseHeightToWidthRatio', [["Nose Top Point", "Nose Bottom Point"], ["Nose Left Point", "Nose Right Point"]]);
        }
        if (visibleFeatures.lipRatio) {
          const adjustUpperLip = ([x, y]) => [x + 3, y];
          const adjustLowerLip = ([x, y]) => [x - 3, y];
          drawLine(adjustUpperLip(data.measurements_coords["Upper Lip Top Point"]), adjustUpperLip(data.measurements_coords["Upper Lip Bottom Point"]));
          drawLine(adjustLowerLip(data.measurements_coords["Lower Lip Top Point"]), adjustLowerLip(data.measurements_coords["Lower Lip Bottom Point"]));
        }
        if (visibleFeatures.bitemporalToFaceWidthRatio) {
          drawRatio('bitemporalToFaceWidthRatio', [["Left Temple Point", "Right Temple Point"], ["Left Face Point", "Right Face Point"]]);
        }

        // New features
        if (visibleFeatures.jawFrontalAngle) {
          drawLine(data.measurements_coords["Jaw Left Point1"], data.measurements_coords["Jaw Left Point2"]);
          drawLine(data.measurements_coords["Jaw Right Point1"], data.measurements_coords["Jaw Right Point2"]);
        }
        if (visibleFeatures.chinAngle) {
          drawLine(data.measurements_coords["Chin Point"], data.measurements_coords["Chin End Left"]);
          drawLine(data.measurements_coords["Chin Point"], data.measurements_coords["Chin End Right"]);
        }
        if (visibleFeatures.leftCantileTilt) {
          drawLine(data.measurements_coords["Left Eye Outer Point"], data.measurements_coords["Left Eye Inner Point"]);
        }
        if (visibleFeatures.rightCantileTilt) {
          drawLine(data.measurements_coords["Right Eye Outer Point"], data.measurements_coords["Right Eye Inner Point"]);
        }
      };
    }
  }, [data, imagePath, visibleFeatures]);

  const getTopPercentiles = useMemo(() => {
    const sortedKeys = Object.keys(data.percentiles).sort((a, b) => data.percentiles[b] - data.percentiles[a]);
    return sortedKeys.slice(0, 3);
  }, [data]);

  const getBottomPercentiles = useMemo(() => {
    const sortedKeys = Object.keys(data.percentiles).sort((a, b) => data.percentiles[a] - data.percentiles[b]);
    return sortedKeys.slice(0, 3);
  }, [data]);

  const handleToggleView = () => {
    setShowAllFeatures((prev) => !prev);
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleToggleFeatureView = () => {
    setToggleView((prev) => (prev === 'traits' ? 'ratios' : 'traits'));
  };

  const sortedFeatures = useMemo(() => {
    const features = Object.keys(data.percentiles);
    if (sortOrder === 'best-to-worst') {
      return features.sort((a, b) => data.percentiles[b] - data.percentiles[a]);
    } else if (sortOrder === 'worst-to-best') {
      return features.sort((a, b) => data.percentiles[a] - data.percentiles[b]);
    }
    return features;
  }, [data.percentiles, sortOrder]);

  const userScore = useMemo(() => Math.ceil(data.score * 10), [data.score]);
  const userScorePercentile = useMemo(() => calculateScorePercentile(userScore / 10), [userScore]);

  const handleExploreRoutines = () => {
    // Navigate to the routines page, passing the routines data
    navigate('/routines', { state: { routines } });
  };


  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
        {imagePath && (
          <div
          style={{
            flex: '1 1 30%',
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div className="glow-up-section" onClick={handleExploreRoutines}>
            <div className="glow-up-card">
              <h2>See your personalized glow up routine</h2>
              <p>Click here to explore routines tailored for you!</p>
              <button className="explore-button">Explore Routines</button>
            </div>
          </div>
            <button onClick={handleToggleView} style={{ marginBottom: '10px', padding: '10px', backgroundColor: colors.primary, color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', width: '100%' }}>
              {showAllFeatures ? 'Show Best and Worst Features' : 'Show All Features'}
            </button>
            {showAllFeatures && (
              <div style={{ marginBottom: '10px' }}>
                <label htmlFor="sortOrder">Sort By: </label>
                <select id="sortOrder" value={sortOrder} onChange={handleSortChange}>
                  <option value="default">Default</option>
                  <option value="best-to-worst">Best to Worst</option>
                  <option value="worst-to-best">Worst to Best</option>
                </select>
              </div>
            )}
            <div style={{ minHeight: '400px', maxHeight: `${imageHeight - 850 < 400 ? 400 : imageHeight - 850}px`, overflowY: 'auto', border: '2px solid gray', borderRadius: '10px', padding: '10px', flexGrow: 1 }}>
              {showAllFeatures ? (
                <div>
                  <h3>All Features</h3>
                  {sortedFeatures.map((key) => (
                    <div key={key} style={{ padding: '5px', margin: '5px 0' }}>
                      <strong>{key}:</strong> {data.data[key].toFixed(2)}
                      <ProgressBar percent={data.percentiles[key]} style={{ width: '100%' }} />
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <div>
                    <h3>Best Features</h3>
                    {getTopPercentiles.map((key) => (
                      <div key={key} style={{ padding: '5px', margin: '5px 0' }}>
                        <strong>{key}:</strong> {data.data[key].toFixed(2)}
                        <ProgressBar percent={data.percentiles[key]} style={{ width: '100%' }} />
                      </div>
                    ))}
                  </div>
                  <div>
                    <h3>Worst Features</h3>
                    {getBottomPercentiles.map((key) => (
                      <div key={key} style={{ padding: '5px', margin: '5px 0' }}>
                        <strong>{key}:</strong> {data.data[key].toFixed(2)}
                        <ProgressBar percent={data.percentiles[key]} style={{ width: '100%' }} />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {imagePath && (
          <div style={{ flex: '1 1 40%', padding: 30 }}>
            <ProcessedImage canvasRef={canvasRef} style={{ 
                border: `12px solid ${colors.primary}`, 
                borderRadius: '25px'
              }} />
          </div>
        )}
        <div style={{ flex: '1 1 30%', marginLeft: '20px', marginTop: '30px', }}>
          {data && (
            <>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                <strong style={{ fontSize: '24px', color: 'black' }}>Front Profile Score: {userScore} </strong>
                <ProgressBar percent={userScorePercentile} style={{ width: '100%' }} />
                <BellCurve score={userScorePercentile} style={{ width: '100%' }} />
              </div>
              <button onClick={handleToggleFeatureView} style={{ marginBottom: '10px', padding: '10px', backgroundColor: colors.primary, color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', width: '100%' }}>
                {toggleView === 'traits' ? 'Show Ratios' : 'Show Traits'}
              </button>
              <div style={{ maxHeight: `${imageHeight - 850 < 400 ? 400 : imageHeight - 850}px`, overflowY: 'auto', padding: '10px', flexGrow: 1, border: '2px solid gray', borderRadius: '10px' }}>
                {toggleView === 'traits' ? (
                  <FrontTraitsToggle visibleFeatures={visibleFeatures} toggleFeature={toggleFeature} />
                ) : (
                  <FrontRatiosToggle visibleFeatures={visibleFeatures} toggleFeature={toggleFeature} />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {/* {data && (
        <>
          <h3>Front Profile Data</h3>
          {Object.keys(data.data).map((key) => (
            <div key={key} style={{ padding: '5px', margin: '5px 0' }}>
              <strong>{key}:</strong> {data.data[key]}
              <ProgressBar percent={data.percentiles[key]} style={{ width: '100%' }} />
            </div>
          ))}
        </>
      )} */}
    </div>
  );
}

export default FrontScanResultsComponent;
